import { Link } from 'gatsby';
import React from 'react';
import 'lazysizes';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FlexCard = (props) => {
  return (
    <div className="card">
      <div className={props.rowType}>
        <div className="col">
          <div className="card__image">
            <img effect="blur" src={props.image} alt={props.imageAlt} srcSet={props.srcSet} className="lazyload" sizes={props.sizes} width="1200" height="800" loading="lazy" />
          </div>
        </div>
        <div className="col">
          <div className="card__content">
            <h3>{props.header}</h3>
            <h4>{props.subheader}</h4>
            <p>{props.text}</p>
            {props.children}
            {props.subtext && <p className="subtext">{props.subtext}</p>}
            {props.buttonChildren && props.buttonChildren}
            {props.externalURL && <a href={props.externalURL} target="_blank" rel="noopener noreferrer" ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></a> }
            {props.phone && <a href={props.phone} target="_blank" rel="noopener noreferrer" className='phone' ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></a> }
          <div className='btn-routes'>
            {props.route && <Link to={props.route} ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></Link> }
            {props.routeTwo && <Link to={props.routeTwo} ><button className={`btn btn-two ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnTwoText}</button></Link> }
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlexCard;
